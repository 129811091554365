import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from '@emotion/styled'
import SelectIcon from '../icons/SelectIcon'
import { truncate } from 'lodash'

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem auto 2.5rem;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  padding: 0 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
`

const Button = styled(Link)`
  border-radius: 2px;
  margin: 0 0 0 0.5rem;
  padding: 0.2em;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;
  font-size: 0.8em;
  color: #aeaeae;

  &:hover {
    background: ${(props) => props.theme.colors.highlight};
    color: white;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    padding: 0.5em;
  }
`

// const BUtton = styled(Link)`
//   background: ${props => props.theme.colors.primary};
//   color: white;
//   padding: 1rem;
//   border-radius: 2px;
//   margin: 0 0 0 0.5rem;
//   cursor: pointer;
//   text-decoration: none;
//   transition: 0.3s all;
//   &:hover {
//     background: ${props => props.theme.colors.highlight};
//   }
//   @media (hover: none) {
//     background: ${props => props.theme.colors.primary} !important;
//   }
// `

const Numbers = styled.div`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 2px;
  display: inline-block;
  float: left;
  color: ${(props) => props.theme.colors.text};
  padding: 1rem;
  background: white;
  position: relative;
  transition: 0.3s all;
  svg {
    fill: ${(props) => props.theme.colors.text};
    margin: 0 0 0 0.25rem;
    transition: 0.3s all;
  }
  &:hover {
    background: ${(props) => props.theme.colors.tertiary};
  }
  // @media (hover: none) {
  //   background: white !important;
  // }
`

const Select = styled.select`
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: transparent;
  option {
    color: black;
  }
`

const range = (start, len, step = 1) =>
  len
    ? new Array(len)
        .fill(undefined)
        .map((_, i) => +(start + i * step).toFixed(4))
    : []

const Pagination = (props) => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.context.paginationPath}/${e.target.value}`
        : `${props.context.paginationPath}/`
    )
  }
  const { humanPageNumber, numberOfPages } = props.context

  // context: {…}
  // basePath: ""
  // humanPageNumber: 1
  // limit: 6
  // nextPagePath: "/2"
  // numberOfPages: 2
  // pageNumber: 0
  // paginationPath: ""
  // previousPagePath: ""​​
  // skip: 0

  const getPageLinks = () => {
    const maxPages = 4

    const previousPage =
      humanPageNumber === 1 ? humanPageNumber : humanPageNumber - 1
    const pageRange = range(previousPage, numberOfPages + 1 - previousPage)
    // console.log(`Page range: ${pageRange}`)
    const truncatedRange = pageRange.slice(0, maxPages)
    if (pageRange[0] > 2) truncatedRange.unshift(null)
    if (pageRange[0] > 1) truncatedRange.unshift(1)
    // if (pageRange[0] + 1 === numberOfPages && pageRange[0] - 1 > 0) {
    //   truncatedRange.splice(
    //     pageRange.length - 1 - maxPages,
    //     0,
    //     pageRange[0] - 1,
    //     );
    //   }

    if (pageRange[0] + maxPages < numberOfPages) truncatedRange.push(null)

    if (pageRange[0] + maxPages - 1 < numberOfPages) {
      truncatedRange.push(numberOfPages)
    }
    // console.log(truncatedRange)

    return truncatedRange.map((page, i) =>
      page === null ? (
        <Spacer key={`PaginatorPath_spacer_${i}`} aria-hidden={true} />
      ) : (
        <PageNumberButton
          key={`PaginatorPage_${page}`}
          to={getFullPath(page)}
          style={{ color: humanPageNumber === page ? '#252121' : '#aeaeae' }}
          className="Paginator__pageLink"
        >
          {page}
        </PageNumberButton>
      )
    )
  }

  const getFullPath = (n) =>
    n === 1
      ? `${props.context.paginationPath}/`
      : `${props.context.paginationPath}/${n}`

  return (
    <>
      {props.context.numberOfPages > 1 && (
        <Wrapper>
          <div>
            {props.context.previousPagePath && (
              <Button to={`${props.context.previousPagePath}`}>
                <span>{`<<`}</span> Prev
              </Button>
            )}
          </div>
          {getPageLinks()}
          {props.context.nextPagePath && (
            <Button style={{ order: 3 }} to={`${props.context.nextPagePath}`}>
              Next <span>{`>>`}</span>
            </Button>
          )}
        </Wrapper>
      )}
    </>
  )
}

const PageNumberButton = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: ${(p) => p.theme.colors.primary};
  margin: 0 0.3em;
  transition: 0.3s all;
  padding: 0.2em;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    margin: 0 0.7em;
    padding: 0.5em;
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.secondary};
    color: white !important;
  }
`

const Spacer = styled.span`
  color: #7c7a7a;
  &::before {
    content: '...';
  }
`

export default Pagination
